import * as React from 'react';
import {useRef} from 'react';
import {
    Alert as MantineAlert,
    Anchor,
    AppShell,
    Button,
    Center,
    Dialog,
    Flex,
    PasswordInput,
    Stack,
    TextInput,
    Title
} from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";
import Footer from "../Footer";
import {isEmail, useForm} from "@mantine/form";
import {IconInfoCircle} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";

const SignIn = () => {
    const emailRef = useRef(null);
    const [message, setMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('warning')
    const [opened, {toggle, close}] = useDisclosure(false);
    const navigate = useNavigate()

    const form = useForm({
        mode: 'uncontrolled',
        validateInputOnChange: true,
        initialValues: {email: '', password: ''},
        validate: {
            email: isEmail('Invalid email'),
        },
    });


    const handleSubmit = async (values) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({"email": values.email, "password": values.password}),
            });

            if (response.ok) {
                const token = await response.json();
                // Redirect if unconfirmed email
                if (!token["is_confirmed"]) {
                    setSeverity('warning')
                    if (!opened) toggle()
                    setMessage("This user needs to confirm their email address");
                    navigate('/confirm-email', { state: { email: values.email } });
                    return
                }
                // Store the token securely in browser storage (e.g., localStorage)
                await localStorage.setItem('access_token', token["access"]);
                await localStorage.setItem('refresh_token', token["refresh"]);

                // Redirect the user to the desired page or update the application state
                // to reflect the logged-in state
                navigate('/')

            } else {
                const errorData = await response.json();
                setSeverity('warning')
                if (!opened) toggle()
                setMessage(errorData.detail);
            }
        } catch (error) {
            console.error(error)
            setSeverity('warning')
            if (!opened) toggle()
            setMessage('An error occurred. Please try again.');
        }
    };

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        const email = emailRef.current.value;
        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/user/forgot_password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({"email": email}),
            });

            if (response.status === 200) {
                setSeverity('success');
                if (!opened) toggle()
                setMessage(await response.json())
            } else {
                setSeverity('warning');
                if (!opened) toggle()
                setMessage(await response.json())
            }
        } catch (error) {
            setSeverity('warning')
            if (!opened) toggle()
            setMessage('An error occurred. Please try again.');
        }
    };

    return (
        <React.Fragment>
            <AppShell.Main style={{paddingRight: "0px"}}>
                    <Flex
                      mih={"90vh"}
                      justify="center"
                      align="center"
                      direction="column"
                      wrap="wrap"
                    >
                        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>

                        <Stack>
                            <Center>
                                <Title order={1}>
                                    Sign in
                                </Title>
                            </Center>
                                <TextInput withAsterisk required ref={emailRef}
                                           label="Email" placeholder="Email" {...form.getInputProps('email')} />
                                <PasswordInput
                                    withAsterisk
                                    required
                                    label="Password"
                                    placeholder="Password"
                                    {...form.getInputProps('password')}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="filled"
                                >
                                    Sign In
                                </Button>
                                <Anchor href="" onClick={handleForgotPassword}>
                                    Forgot password?
                                </Anchor>
                                <Anchor href="/sign-up">
                                    {"Don't have an account? Sign up"}
                                </Anchor>

                        </Stack>
                        </form>
                    </Flex>
            </AppShell.Main>
            <Footer/>
            <Dialog component={MantineAlert} variant="light"
                    color={severity === "success" ? "green" : "red"}
                    title={severity === "success" ? "Success" : "Warning"} icon={<IconInfoCircle/>}
                    opened={opened} withCloseButton onClose={close} size="lg" radius="md">
                {message}
            </Dialog>
        </React.Fragment>
    );
}

export default SignIn;