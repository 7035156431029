import React, {useContext, useEffect, useState} from 'react';
import mySvg from "../Images/crochetLogo.svg";
import {useDisclosure, useMediaQuery} from '@mantine/hooks';
import {
    ActionIcon,
    AppShell,
    Burger,
    Button,
    Group,
    Image,
    Menu,
    NavLink,
    rem,
    Tooltip,
    useMantineColorScheme
} from '@mantine/core';
import {Link as RouterLink, Link, Route, Routes, useLocation, useNavigate} from "react-router-dom";

import AlphaPatternCreate from "./AlphaPattern/AlphaPatternCreate";
import {
    IconAbacus,
    IconChartGridDots,
    IconHome,
    IconLogout,
    IconMoon,
    IconPokerChip,
    IconRosette,
    IconSun,
    IconUser
} from "@tabler/icons-react";
import RowCounterShell from "./RowCounter/RowCounterShell";
import MarketplaceShell from "./Marketplace/MarketplaceShell";
import TutorialsShell from "./Tutorials/TutorialsShell";
import AlphaPatternShell from "./AlphaPattern/AlphaPatternShell";
import NotFound from "./NotFound";
import AlphaPatternEdit from "./AlphaPattern/AlphaPatternEdit";
import SymbolPatternEdit from "./SymbolPattern/SymbolPatternEdit";
import SymbolPatternShell from "./SymbolPattern/SymbolPatternShell";
import HomeShell from "./Home/HomeShell";
import SignIn from "./Authentication/SignIn";
import SignUp from "./Authentication/SignUp";
import ForgotPassword from "./Authentication/ForgotPassword";
import AlphaPatternManager from "./AlphaPattern/AlphaPatternManager";
import SymbolPatternManager from "./SymbolPattern/SymbolPatternManager";
import SymbolPatternCreate from "./SymbolPattern/SymbolPatternCreate";
import RowCounterCreate from "./RowCounter/RowCounterCreate";
import RowCounterManager from "./RowCounter/RowCounterManager";
import RowCounterEdit from "./RowCounter/RowCounterEdit";
import {makeAuthenticatedRequest} from "../Utils/authenticated_request";
import {UserContext} from "../Context/User";
import PremiumShell from "./Premium/PremiumShell";
import ConfirmEmail from "./Authentication/ConfirmEmail";
import EmailConfirmation from "./Authentication/EmailConfirmation";


const Shell = () => {
    const [navBarOpened, {toggle: navBarToggle}] = useDisclosure();
    const [asideOpened, {toggle: asideToggle}] = useDisclosure();
    const [active, setActive] = useState(-1);
    const [hoveringLogo, setHoveringLogo] = useState(false);

    const location = useLocation();
    const { colorScheme, setColorScheme } = useMantineColorScheme();
    const { user, updateUser } = useContext(UserContext);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const navigate = useNavigate()



    const data = [
        {
            icon: IconHome,
            label: 'Home',
            description: 'Home page',
            href: '',
        },
        {
            icon: IconChartGridDots,
            label: 'Alpha Pattern',
            description: 'Create patterns from a grid of colors',
            href: 'alphapattern',
        },
        {
            icon: IconPokerChip,
            label: 'Symbol Pattern',
            description: 'Create patterns from a series of symbols',
            href: 'symbolpattern',
        },
        // {
        //     icon: IconBackhoe,
        //     label: 'Pattern Builder',
        //     description: 'Customise an item and have a pattern generated personally for you',
        //     href: 'patternbuilder',
        // },
        {
            icon: IconAbacus,
            label: 'Row Counter',
            description: 'Keep track of where you are in your project',
            href: 'rowcounter'
        },
        // {
        //     icon: IconBuildingStore,
        //     label: 'Marketplace',
        //     description: 'Buy and sell patterns',
        //     href: 'marketplace',
        // },
        // {
        //     icon: IconSchool,
        //     label: 'Tutorials',
        //     description: 'Learn everything you need to know about patterns and crocheting',
        //     href: 'tutorials'
        // },
        {
            icon: IconRosette,
            label: 'Premium',
            description: 'Access the many benefits of premium',
            href: 'premium'
        },
    ];
    const routes = [
        {path: '', element: <HomeShell/>, children: []},
        {path: 'sign-in', element: <SignIn/>, children: []},
        {path: 'sign-up', element: <SignUp/>, children: []},
        {path: 'forgot-password', element: <ForgotPassword/>, children: []},
        {path: 'confirm-email', element: <ConfirmEmail/>, children: []},
        {path: 'email-confirmation/:token', element: <EmailConfirmation/>, children: []},
        {
            path: 'alphapattern', element: <AlphaPatternShell/>,
            children: [
                {path: 'create', element: <AlphaPatternCreate/>},
                {path: 'edit', element: <AlphaPatternEdit/>},
                {path: '', element: <AlphaPatternManager/>}
            ]
        },
        {path: 'symbolpattern', element: <SymbolPatternShell/>,
            children: [
                {path: 'create', element: <SymbolPatternCreate/>},
                {path: 'edit', element: <SymbolPatternEdit/>},
                {path: '', element: <SymbolPatternManager/>},
            ]},
        // {path: 'patternbuilder', element: <PatternBuilderManager/>, children: []},
        {path: 'rowcounter', element: <RowCounterShell/>,
                        children: [
                {path: 'create', element: <RowCounterCreate/>},
                {path: 'edit', element: <RowCounterEdit/>},
                {path: '', element: <RowCounterManager/>},
            ]},
        {path: 'marketplace', element: <MarketplaceShell/>, children: []},
        {path: 'tutorials', element: <TutorialsShell/>, children: []},
        {path: 'premium', element: <PremiumShell/>, children: []},
        {path: '*', element: <NotFound/>, children: []},
    ];
    const routeElements = routes.map((route, routeIndex) => (
        <Route key={routeIndex} path={route.path} element={route.element}>
            {route.children.map((child, childIndex) => (
                <Route key={childIndex} path={child.path} element={child.element}/>
            ))}
        </Route>
    ));

    const updateUserColorScheme = async (colorScheme) => {
        try {
            const body = JSON.stringify({color_scheme: colorScheme})
            const response = await makeAuthenticatedRequest(
                `${process.env.REACT_APP_API_HOST}/user/${user.id}/`,
                {method: 'PATCH',
                body},
            );

            if (response.ok) {
                const jsonData = await response.json();
            } else {
                console.error("Request failed with status:", response.status);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleLogout = () => {
        updateUser(null)
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');

        navigate('');
      };

    const handleColorScheme = (user) => {
        const updatedColorScheme = colorScheme === 'light' ? 'dark' : 'light'
        setColorScheme(updatedColorScheme)
        if (user) {
            updateUserColorScheme(updatedColorScheme)
        }
    }

    useEffect(() => {
        // Extract the pattern type from the current location
        const patternType = location.pathname.split('/')[1];

        // Find the index of the pattern type in the data array
        const index = data.findIndex((item) => item.href === patternType);

        // Update the active index if found
        if (index !== -1) {
            setActive(index);
        }

        if (navBarOpened) {
            navBarToggle()
        }
    }, [location.pathname]);

    return (
        <AppShell
            header={{
                height: "10vh",
                maxHeight: "10vh",
            }}
            // footer={{
            //     height: "5vh",
            //     maxHeight: "5vh",
            // }}
            navbar={{
                breakpoint: 1000000000,
                collapsed: {mobile: !navBarOpened},
            }}
            aside={{
                width: "20%",
                collapsed: {mobile: asideOpened}
            }}
            // padding="md"
        >
            <AppShell.Header>
                <Group
                    p={"5px"}
                    justify={"space-between"}
                >
                    <Tooltip label="Menu">
                            <Burger
                        opened={navBarOpened}
                        onClick={navBarToggle}
                        size="xl"
                    />
                    </Tooltip>
                    {!isMobile &&
                    <Tooltip label="Home">
                        <Image
                            onClick={() => {navigate('/');
}}
                        src={mySvg}
                        style={{
                            height: '8vh',
                        }}
                        onMouseEnter={() => setHoveringLogo(true)}
                        onMouseLeave={() => setHoveringLogo(false)}
                    ></Image>
                    </Tooltip>
                    }
                    <Group
                        p={"5px"}
                    >
                                            {user &&

                        <Button leftSection={<IconRosette size={14} />} component={RouterLink} to="/premium">
                            Premium
                        </Button>
                                            }
                        {user ?
                            <Menu
                                width={200}
                                withArrow
                                shadow="md">
                            <Menu.Target>
                                <Tooltip label="User">
                                <ActionIcon
                                    variant="outline"
                                    title="User"
                                >
                                    <IconUser style={{width: 18, height: 18}}/>
                                </ActionIcon>
                                    </Tooltip>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Label>Profile</Menu.Label>
                                <Menu.Item
                                    onClick={() => handleColorScheme(true)}
                                    leftSection={colorScheme === 'light' ? (
                                                <IconSun style={{ width: rem(14), height: rem(14) }}/>
                                            ) : (
                                                <IconMoon style={{ width: rem(14), height: rem(14) }}/>
                                            )}>
                                  Default color scheme
                                </Menu.Item>
                                        <Menu.Item
                                            onClick={() => handleLogout()}
                                            leftSection={<IconLogout style={{ width: rem(14), height: rem(14) }} />}>
          Logout
        </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                            :
                            <Tooltip label="Toggle Color Scheme">
                                        <ActionIcon
                                            variant="outline"
                                            onClick={() => handleColorScheme(false)}
                                            title="Toggle color scheme"
                                        >
                                            {colorScheme === 'light' ? (
                                                <IconSun style={{width: 18, height: 18}}/>
                                            ) : (
                                                <IconMoon style={{width: 18, height: 18}}/>
                                            )}
                                        </ActionIcon>
                                    </Tooltip>
                        }

                    </Group>
                </Group>
            </AppShell.Header>

            <AppShell.Navbar>
                {data.map((item, index) => (
                    <Link to={item.href} key={item.label} style={{textDecoration: 'none', color: 'inherit'}}>

                        <NavLink
                            component="button"
                            active={index === active}
                            label={item.label}
                            description={item.description}
                            leftSection={<item.icon size="1rem" stroke={1.5}/>}
                            onClick={() => setActive(index)}
                            color="violet"
                        />
                    </Link>
                ))}
            </AppShell.Navbar>

            <Routes>
                {routeElements}
            </Routes>
            {/*<AppShell.Footer>*/}
            {/*    <BackgroundImage src={mySvg} style={{height: "100%", backgroundPosition: "center -100%"}}>*/}

            {/*    </BackgroundImage>*/}
            {/*</AppShell.Footer>*/}
        </AppShell>
    );
}

export default Shell;